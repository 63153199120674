/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : index.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  @media screen and (max-width: 400px) {
    ._ZKlLx {
      margin: 10px !important;
    }
  }
/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : Calendar.module.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

._25CjQ {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

._mMnEp {
  justify-content: space-between;
  display: flex;
}

._2EbjH {
  display: block;
}

._2ZJvp {
  width: 100%;
  border-spacing: 10px;
  margin-top: -5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

._1FrEU {
  text-align: center;
}
._1FrEU svg {
  color: #5096e5;
  transform: rotate(-90deg);
}

._1pIDE {
  display: flex;
  align-items: center;
  color: #909499;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  margin-top: 100px;
}

._1pIDE::after {
  display: block;
  content: '';
  width: 100%;
  border-top: 1.5px dotted #afb2b6;
  position: absolute;
  bottom: -1px;
  left: 0;
}

._3Qarr {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 10px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #F9F9FA 70%);
  height: 156px;
}

._1_Pv0 {
  height: 9px;
  width: 6px;
}

._1IVHG {
  display: flex;
  color: #afb2b6;
  margin-left: 5px;
  transform: rotate(-90deg);
}

/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : TopHeader.module.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

._2OPFC {
  justify-content: center;
  display: flex;
  align-items: center;
}

._-ijrq {
  display: block;
}

._EW260 {
  margin-bottom: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

._2xv89 {
  color: #405162;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 32px;
  margin-bottom: 1px;
}

._3kyIQ {
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.26px;
  line-height: 25px;
}

._2UCw8 {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

._TM6U3 {
  color: #707d8a;
  font-size: 16px;
  letter-spacing: 0;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-self: center;
  margin-right: 10px;
  cursor: pointer;
}

._2hEh2 {
  height: 100%;
  position: absolute;
  left: -55px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
}

._22Fm- {
  border: 2px solid #d9dde0;
  border-radius: 5px;
  height: 38px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* .navigatorInnerWrap:first-child {
  border-right: 2px solid #d9dde0;
} */

._2FBBS {
  box-sizing: border-box;
  width: 38px;
  height: 38px;
  color: #8C94A1;
  cursor: pointer;
  border-radius: 6px;
  background-color: #EDEEF2;

}
._2FBBS span {
  display: flex;
  align-items: center;
  justify-content: center;
}
._2FBBS:hover {
  background-color: #f9f9fa;
}

._4o-YF {
  min-width: 250px;
  padding: 0 20px;
}

._1Qth3 {
  position: relative;
  height: 38px;
}

/* .navigatorInnerWrapRight {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
} */

._2mN2R {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

._33fyd {
  width: 38px;
  box-sizing: border-box;
  transform: scaleX(-1);
  height: 100%;
  width: 100%;
}

._1EwDc {
  background-color: #fafafc !important;
  pointer-events: none;
  color: #d9dde0;
  cursor: default;
}

._4NWgu {
  height: 14px;
  width: 10px;
}

/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : TableHeader.module.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

.__qzbx {
  color: #8c94a1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : TableContent.module.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

._2Y_iI {
  width: 14.28571429%;
  height: 156px;
  padding: 10px 0;
  position: relative;
  border-radius: 6px;
  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  color: #333333;
}

._3JsKr {
  width: 100%;
  height: auto;
  padding: 20px;
  position: relative;
  border-radius: 6px;
  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  color: #333333;
}

._3MmEp {
  width: 10%;
  justify-content: flex-end;
  text-align: center;
  background-color: inherit;
  display: flex;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}

._1CERj {
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

._3t6hD {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

._1DAQj {
  width: 100%;
  height: 100%;
}

._GlluR {
  background-size: cover;
  background-color: #EDEEF2;
  background-repeat: no-repeat;
  color: #8C94A1;
}

._3-PoW {
  display: flex;
  justify-content: center;
  align-items: center;
}

._RDQsJ {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._1kPF9 {
  box-sizing: border-box;
  pointer-events: none;
  background-color: #ffffff;
  height: 156px;
  padding-bottom: 0;
}

._2ich7 {
  display: flex;
  padding-right: 3px;
  margin-top: 16px;
  width: 100%;
  justify-content: flex-end;
  font-size: 13px;
  letter-spacing: 0;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 15px;
  color: #8C94A1;
  font-weight: 500;
}

._1fgjk {
  border-radius: 14px;
  background-color: #F5F6F7;
  padding: 0 14px;
  color: #8C94A1;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-weight: 500;
  letter-spacing: -0.19px;
  font-size: 14px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 26px;
  text-align: center;
  margin-bottom: 6px;
}

._19Olm {
  border-radius: 14px;
  background-color: #F5F6F7;
  padding: 0 2px;
  color: #8C94A1;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-weight: 500;
  letter-spacing: -0.19px;
  font-size: 14px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 26px;
  text-align: center;
  margin-bottom: 6px;
}
._19Olm:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1000px) {
  ._19Olm {
    padding: 0 14px;
  }
}

@media screen and (min-width: 900px) {
  ._19Olm {
    padding: 0 8px;
  }
}

._OlOYy {
  cursor: not-allowed;
}


._cneN8 {
  margin-top: 16px;
}

._2KsQJ {
  width: 10%;
  display: flex;
  justify-content: flex-start;
  color: #909499;
  font-size: 14px;
  letter-spacing: 0;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 28px;
}
/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : AvailabilityDialog.module.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */


._qPLew {
  width: 100%;
}

._2yLYW {
  background-color: #fafafc;
  border-radius: 8px;
  min-width: 100%;
  box-sizing: border-box;
  max-height: 80%;
  position: relative;
}

@media screen and (min-width: 600px) {
  ._2yLYW {
    width: 500px;
  }
}

._2uXH6 {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #909499;
  cursor: pointer;
}
._2uXH6:hover {
  color: #646970;
}

._2uXH6 svg {
  height: 24px;
  width: 24px;
}

/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : AddIntervalSection.module.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

/* interval */
._lGqkx {
  width: 100%;
  padding: 30px 16px 25px 16px;
}

@media screen and (min-width: 500px) {
  ._lGqkx {
    padding: 30px;
  }
}

@media screen and (min-width: 600px) {
  ._l1fsE {
    min-width: 100%;
    margin: 0 -5px;
    display: flex;
    justify-content: space-between;
  }

  ._l1fsE ._3K8Lk {
    margin: 0 5px;
    margin-bottom: 20px !important;
  }
}

._1fJ9u {
  display: flex;
}

._2oXiz {
  text-align: center;
  margin-bottom: 25px;
  color: #333333;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
}

._3uVtO {
  margin: 0;
  display: flex;
}

._15VkV {
  width: 100%;
}

._mWCNT {
  margin-bottom: 10px;
}

._3PFH4 {
  margin-right: 10px;
}

._2j_jc {
  margin-right: 25px;
}

._oWmfQ {
  border: 2px solid #D8DBE0;
  border-radius: 6px;
  background-color: #FFFFFF;
  width: 45%;
  padding: 0 20px;
  color: #121a24;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 32px;
  height: 40px;
  display: flex;
  align-items: center;
  -webkit-appearance: none;
}

._oWmfQ::-moz-placeholder {
  line-height: 20px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  color: #C5C8CD;
  font-size: 14px;
  letter-spacing: -0.21px;
}

._oWmfQ:-ms-input-placeholder {
  line-height: 20px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  color: #C5C8CD;
  font-size: 14px;
  letter-spacing: -0.21px;
}

._oWmfQ::placeholder {
  line-height: 20px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  color: #C5C8CD;
  font-size: 14px;
  letter-spacing: -0.21px;
}

._oWmfQ:focus:hover,
._oWmfQ:focus {
  outline: none;
  border-color: #5096e5;
}

._oWmfQ._3pU--,
._oWmfQ._3pU--:hover {
  border-color: #ed4545;
}

._oWmfQ:hover {
  border-color: #afb2b6;
}

._10C8A {
  color: #3D8DF5;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 22px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  cursor: pointer;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

._10C8A svg {
  margin-right: 7px;
}

._1RWd8 {
  width: 100%;
  display: flex;
  height: 54px;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d7d8da;
  color: #d7d8da;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 32px;
  font-size: 17px;
  letter-spacing: 0;
  margin-bottom: 10px;
}

._ALWQf {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  color: #5A6370;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 22px
}

._ALWQf ._33fg-,
._ALWQf ._19ms1 {
  width: 45%;
  text-align: left;
}

._ALWQf ._33fg- {
  margin-right: 10px;
}

._2HCiJ {
  display: flex;
}

._2HCiJ ._3G2Ik {
  height: 36px;
  padding: 0 20px;
  width: 100%;
  justify-content: center;
  border-radius: 6px;
  background-color: #EDEEF2;
  color: #333333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 17px;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

._3G2Ik:hover {
  background-color: #E3E3E5;
}

._3sGSq {
  pointer-events: none;
  opacity: 0.5;
  color: #d7d8da;
}

._niSts {
  width: 100%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._niSts ._3K8Lk {
  width: 100%;
  color: #ffffff;
  border: 2px solid rgba(34,104,224,0.46);
  border-radius: 6px;
  background-color: #3D8DF5;
  line-height: 21px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.22px;
  text-align: center;
  margin-bottom: 8px;
}
._3K8Lk:focus {
  outline: none;
  background-color: #3d80cb;
}
._3K8Lk:hover {
  background-color: #5096E5;
}

._3K8Lk:active {
  background-color: #3d80cb;
}

._1Q9dk {
  color: #ed4545;
  margin-top: 5px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}

._2Dulp {
  display: flex;
  align-items: center;
  color: #C5C8CD;
  cursor: pointer;
}

._2Dulp svg {
  height: 20px;
  width: 20px;
}

._2Dulp:hover {
  color: #646970;
}
._2Dulp:active {
  color: #646970;
}

._2d9Ae {
  color: #3D8DF5;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.19px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 22px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

._2d9Ae:focus {
  outline: none;
}

._2d9Ae:hover {
  color: #69a9f2;
}


@media screen and (max-width: 370px) {
  ._oWmfQ {
    width: 40%;
    padding: 0 5px;
  }

  ._ALWQf ._33fg-,
  ._ALWQf ._19ms1 {
    width: 40%;
  }

  ._2HCiJ ._3G2Ik {
    width: 100%;
    justify-content: center;
  }
}
/* Apply Multiple */

._dLa_l {
  width: 100%;
  position: relative;
}

._3Avth {
  color: #333333;

  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  position: relative;
  margin-bottom: 25px;
}

._2-ix1 {
  position: absolute;
  left: 0;
  top: 0;
  color: #646970;
  cursor: pointer;
}

._2-ix1:hover {
  color: #121a24;
}

/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : WeekDayFrom.module.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

._11TmF {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

._BVddi {
  border-radius: 6px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 24px;
}

._BVddi._2Aoz9 {
  border: 2px solid rgba(34,104,224,0.46);
  border-radius: 6px;
  background-color: #3D8DF5;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.22px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 17px;
  text-align: center;
}

._BVddi._3h6Va {
  border-radius: 6px;
  background-color: #EDEEF2;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 17px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  text-align: center;
  border: none;
  outline: none;
  margin-right: 10px;
}


._3X0Q- {
  pointer-events: none;
}

._1ePKl{
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 0 0 1px rgba(90,99,112,0.16);
}

._qWjon {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  color: #333333;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  letter-spacing: -0.22px;
  line-height: 17px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 0 0 #EDEEF2, 0 1px 0 0 #EDEEF2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
}

._qWjon input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

._3s8EW {
  position: absolute;
  right: 18px;
  top: 10px;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 1.2px solid #C5C8CD;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

._qWjon:hover{
  background-color: #F5F6F7;
}

._1ePKl ._qWjon:last-child {
  box-shadow: none;
  border-radius: 0 0 6px 6px;
}

._1ePKl ._qWjon:first-child {
  border-radius: 6px 6px 0 0;
}

._qWjon input ~ ._3s8EW ._2ov_6 {
  display: none;
}

._qWjon input:checked ~ ._3s8EW ._2ov_6 {
  display: block;
}

._3s8EW ._kdMDo {
  height: 8px;
  width: 8px;
  color: #C5C8CD;
}

._3s8EW ._2ov_6 {
  width: 12px;
  height: 12px;
  color: #ffffff;
}

._qWjon input ~ ._3s8EW ._kdMDo {
  display: block;
}

._qWjon input:checked ~ ._3s8EW ._kdMDo {
  display: none;
}

._qWjon input:checked ~ ._3s8EW {
  border: 1px solid #73C765;
  background-color: #7DD46E;
}
/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : Modal.module.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

._2sQ5- {
    position: fixed;
    z-index: 1300;
    inset: 0px;
}

._1s0m1 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: -1;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

._3YHS_ {
    height: 100%;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

._2bekE {
    max-width: 500px;
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    margin: 32px;
    position: relative;
    overflow-y: auto;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

._N89v0 {
    overflow: hidden;
}
/**
 * --------------------------------------------------------------------------
 * Scheduler-calendar : OverrideConfirmationDialog.module.css
 * Licensed under MIT (https://github.com/stead-global/scheduler-calendar/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

._1QEFE {
  text-align: center;
  color: #333333;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  margin-bottom: 25px;
  padding: 0 40px;
}

._1fWZl {
  width: 100%;
}

._1VJ2n {
  background-color: white;
  border-radius: 8px;
  padding: 30px 16px 25px 16px;
  width: 100%;
  box-sizing: border-box;
  max-height: 80%;
  position: relative;
}

@media screen and (min-width: 500px) {
  ._1VJ2n {
    width: 400px;
    padding: 30px 25px 25px 25px;
  }
}


._3ZREq {
  color: #333333;
  font-size: 17px;
  letter-spacing: 0;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 0 35px 0;
}

._i3taR {
  margin: 0 -5px;
  display: flex;
  align-items: center;
  min-width: 100%;
}

._i3taR ._2qj9n,
._i3taR ._1TWRj {
  width: 50%;
  margin: 0 5px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.22px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  border-radius: 6px;
  line-height: 21px;
  cursor: pointer;
}

._i3taR ._2qj9n {
  color: #ffffff;
  border: 2px solid rgba(34,104,224,0.46);
  background-color: #3D8DF5;
}
._i3taR ._2qj9n:hover {
  background-color: #5096E5;
}

._i3taR ._2qj9n:focus,
._i3taR ._2qj9n:active {
  background-color: #3d80cb;
  outline: none;
}

._i3taR ._1TWRj {
  border: 2px solid #909499;
  background-color: #ffffff;
  color: #5096e5;
}
._i3taR._1TWRj:hover,
._i3taR._1TWRj:active,
._i3taR._1TWRj:focus {
  background-color: #E3E5E7;
  outline: none;
}

@media screen and (max-width: 370px) {
  ._i3taR {
    flex-wrap: wrap;
  }
  ._i3taR ._2qj9n,
  ._i3taR ._1TWRj {
    width: 100%;
  }
  ._i3taR ._2qj9n {
    margin-bottom: 16px;
  }
}
